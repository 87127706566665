import React, { Component } from 'react'
import "./TitleSeq.css"
import { Button } from "@chakra-ui/react"

class TitleSeq extends Component {
    render() {
        return (
            <div className="titleMain">
                <div className="titleInner">
                    <div id="titleText">
                        Welcome to Lilypads.cc test changes.
                    </div>
                    <div id="righty" className="buttonsWide">
                        <Button onClick={() => {this.props.handleToDiceSelect()}} color="rgb(0, 255, 51)" padding={5} borderColor="black" backgroundColor="black" textAlign="right" borderStyle="solid" borderRadius={10} fontSize={18}> 
                            {` Select Dice `} 
                        </Button>
                    </div>
                </div>
                <div className="smallOnly">
                    <div id="righty">
                        <Button onClick={() => {this.props.handleToDiceSelect()}} color="rgb(0, 255, 51)" padding={5} borderColor="black" backgroundColor="black" textAlign="right" borderStyle="solid" borderRadius={10} fontSize={18}> 
                            {` Select Dice `} 
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default TitleSeq