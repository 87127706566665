import axios from './axios';
import React, { useEffect, useState } from 'react'
import { Button, Flex, Link, SimpleGrid } from '@chakra-ui/react';

import "./LandingPage.css";

function LandingPage({handleToDiceSelect}) {
    return (
        <div className="MenuContainer">
            <SimpleGrid height="100%" minChildWidth="240px" padding="2vh" spacing={20}>
                <Flex key={"ToDice"} alignItems="center" direction="column" justifyContent="center">
                    <Button onClick={() => {handleToDiceSelect()}} height={50} width={200} color="rgb(0, 255, 51)" backgroundColor="black" borderColor="black" textAlign="center" borderStyle="solid" borderRadius={10} fontSize={25}> 
                        <div>
                            <div>
                                PROCEED
                            </div>
                            <div style={{color: "#4A4A4A", fontSize: "20px"}}>
                                To dice selection page
                            </div>
                        </div>
                    </Button>
                </Flex>
            </SimpleGrid>
        </div>
    )
}

export default LandingPage
