import React from 'react'

function Pig1Page() {
    return (
        <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div>you made it to pig1</div>
        </div>
    )
}

export default Pig1Page
