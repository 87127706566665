import './App.css';
import TitleSeq from "./TitleSeq.js"
import LandingPage from "./LandingPage.js"
import DicePage from "./DicePage.js"
import Pig1Page from "./Pig1Page.js"
import BadPage from "./BadPage.js"
import { Component } from 'react';
import { Box, ThemeProvider } from '@chakra-ui/react';

class App extends Component{

  state={
    page:"default",
    //signedIn:false,
    //pages:["Fishing", "Music", "Cooking", "Chess"]
  }

  // Sure, I could programmatically set the pages array, but doing it by hand saves me database calls,
  // and is thus faster to load the landing page, which I believe is important.

  // So when we want to update our pages array, we must remember to also update our google form.

  handleContinue = (pageName) => {
    this.setState({page: pageName});
  }

  handleReset = () => {
    this.handleContinue("default");
  }

  // handleAboutUs = () => {
  //   this.handleContinue("about");
  // }

  // handleSignIn = () => {
  //   this.handleContinue("sign");
  // }

  // handleSuccessfulSignIn = () => {
  //   this.handleContinue("success");
  // }

  // exeSignIn = () => {
  //   this.setState({signedIn: true});
  // }

  // exeSignOut = () => {
  //   this.setState({signedIn: false});
  // }

  getPage = () => {
    return this.state.page;
  }

  // DIVIDER //

  handleToDiceSelect = () => {
    this.handleContinue("dice")
  }

  handleToPigArena = () => {
    this.handleContinue("pig1")
  }


  render() {
    return (
      <Box className="app" bgGradient="linear(115deg, #404040, #1A1A1A)">
        <TitleSeq handleToDiceSelect={this.handleToDiceSelect}/>
        {
          (this.getPage() === "default")
          ? <LandingPage handleToDiceSelect={this.handleToDiceSelect}/>
          : (this.getPage() === "dice")
          ? <DicePage handleToPigArena={this.handleToPigArena}/>
          : (this.getPage() === "pig1")
          ? <Pig1Page/>
          : <BadPage/>
        }
        {/* {
          (this.getPage() === "default")
          ? <Menu handleContinue={this.handleContinue} cats={this.state.pages}/>
          : (this.getPage() === "about")
          ? <About/>
          : (this.getPage() === "sign")
          ? <Signin cats={this.state.pages} handleSuccess={this.handleSuccessfulSignIn} signInFunc={this.exeSignIn} signOutFunc={this.exeSignOut} handleReset={this.handleReset}/>
          : (this.getPage() === "success") 
          ? <SuccessPage/>
          : <Videos cat={this.state.page}/>
        } */}
      </Box>
    );
  }
}

export default App;