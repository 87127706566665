import React from 'react'

function BadPage() {
    return (
        <div>
            You shouldn't be able to get here.
        </div>
    )
}

export default BadPage
